//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.list-item {
  background: #ff000029;
  padding: 6px;
  border-radius: 8px;
  align-items: baseline;
  color: white;
  cursor: pointer;
}

.avatar-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #dadada;

  .icon {
    color: white;
  }
}

.uploade-icon {
  background: #f26400;
  border: 5px solid #fff;
  border-radius: 50%;
  bottom: 2px;
  color: #fff;
  position: absolute;
  right: 8px;

  i {
    color: white;
  }
}
.trash-icon {
  background: #f26400;
  border: 5px solid #fff;
  border-radius: 50%;
  bottom: 2px;
  color: #fff;
  position: absolute;
  right: 55px;

  i {
    color: white;
  }
}

.crop-container {
  width: 100%;
  height: 300px;
  border: 2px dashed #6a6a6a40;
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
}

.crop-container i {
  color: #6a6a6a40;
  font-size: 300px;
  margin: auto;
}

.crop-container video {
  width: 100%;
  height: 100%;
}

.custom-action {
  display: flex;
  justify-content: end;

  @media (max-width: 900px) {
    justify-content: center;

    button {
      width: 100%;
    }
  }

  button {
    color: white;
    background-color: #009ef7;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    min-width: 64px;
    flex-wrap: wrap;
    height: auto;
    color: #009ef7;
    border: 2px solid;

    background-color: transparent;

    font-weight: 600;
    font-size: 16px;
    padding: 12px 28px;
    border-radius: 8px;

    .icon {
      color:  #009ef7;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

.custom-action-fill .fill {
  background: #009ef7 !important;
  border: 1px solid  #009ef7;
  color: white !important;
}

.page-header {
  border-bottom: 1px dashed #c1c1c1;
  padding-bottom: 20px;
}


@keyframes skeleton-loading {
  0% {
    background-color: #f6f6f6;
  }

  100% {
    background-color: #dbdbdb;
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 15px;
  margin: 0;
  border-radius: 50px;
}

.nav-link {
  cursor: pointer !important;
}

.sort-button {
  background-color: transparent;
  border: none;

  padding: 5px 10px;
  margin: 0;
  line-height: 1;
  font-size: 9px;
  // color: black;
  cursor: pointer;

  transition: transform 0.05s ease-out;
}

.sort-reverse {
  transform: rotate(180deg);
}

.page-numbers {
  background: #e0e0e0;
  display: flex;
  border-radius: 20px;
}

.active-page {
  background: #009ef7;
  color: white;
  box-shadow: 0px 0px 8px #019ef7;
}

.previous,
.next {
  background: #e0e0e0;
  border-radius: 100%;
}

.see-more {
  position: relative;

  &::before {
    background: #009ef7;
    color: white;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }




}

.dropdown {
  width: 100% !important;

  .dropdown-toggle {
    width: 100% !important;
    height: 43.5px !important;
    background: transparent !important;
    border: 1px solid #e1e3ea !important;
    color: #5e6278;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      color: #5e6278 !important;
      // content: '⌄' !important;
    }
  }

  .dropdown-toggle:focus:not(.btn-active) {
    background: transparent !important;
    color: #5e6278;
  }

  .dropdown-toggle.show {
    background: transparent !important;
    color: #5e6278;
  }

  .dropdown-menu {
    width: 100% !important;
    max-height: 300px;
    overflow: auto;
    option {
      height: 30px;
      padding: 0 10px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #009ef7;
        color: white;
      }
    }

    .selected {
      background-color: #009ef7;
      color: white;
    }

    .see-more {
      justify-content: center;
      background-color: #e3e3e3;
      display: flex;
    }
  }
 
} 
.admin-avatar{
    background: #ec6509;
    border-radius: 50%;
    color: white;
    letter-spacing: -1px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

  }

  .post {
    box-shadow: none !important;
    // border: 1.25px solid #f5f5f5;
    border: 1.25px solid #efeeee;
  
    border-radius: 16px !important;
    width: 100%;
    padding: 17px;
    min-height: 185px !important;
  }
  .form-item {
    margin-bottom: 10px;
    label {
      width: auto;
      font-weight: 500;
      font-size: 16px;
      display: block;
      margin-bottom: 10px;
      strong {
        font-size: 0.85em;
      }
    }
    .link {
      font-size: 14px;
      font-weight: 400;
    }
    .m-b-0 {
      margin-bottom: 0 !important;
    }
    .MuiFormControl-root{
      margin: 0 !important;
    }
  }
  .input-search{
    height: 40px;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    padding: 5px;
  }
  .MuiTextField-root {
    min-height: 40px !important;
    margin: 0 !important;
  }